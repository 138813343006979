<template>
  <div class="report-mobile_share">
    <!-- 报告密码 -->
    <van-dialog v-model="showDialog" confirm-button-color="#1890FF" closeOnPopstate @confirm="validate" :before-close="closeDialog" :title="$t('view.report.login.text001')">
      <van-cell-group>
        <van-field v-model="psw" :label="$t('view.report.login.text002')" :placeholder="$t('view.report.login.text001')" @blur="validate" type="password" label-width="5em" />
      </van-cell-group>
    </van-dialog>
    <!-- 全息简历 -->
    <ReportContent v-if="showReport && !isPersonalReport" />
    <!-- 个人报告 -->
    <PersonalReport v-if="showReport && isPersonalReport && !isL5Position" />
    <PersonalReportL5 v-if="showReport && isPersonalReport && isL5Position" />
    <div v-if="!isPersonalReport && !showDialog && showLogin" v-show="!$route.query.isFrom || $route.query.isFrom != 'miniapp'" class="report-mobile_lgbtn">
      <van-button @click="toLogin" type="info" size="normal" class="lgbtn">{{ token ? getAccountInfo : $t('view.report.login.text003') }}</van-button>
    </div>
    <van-dialog v-model="loginVisible" :title="$t('view.report.login.text003')" :showConfirmButton="false" :closeOnClickOverlay="true">
      <AccountPass @confirmLogin="confirmLogin" />
    </van-dialog>
  </div>
</template>
<script>
import dd from 'dingtalk-jsapi'
import ReportContent from '@/components/ReportContent'
import PersonalReport from '@/components/personalReport/personalReport' // 个人报告
import PersonalReportL5 from '@/components/personalReport/personalReport-L5'
import { checkReportPwd } from '@/api/estimate'
import { getAiInterLabelConfig } from '@/api/admin'
import AccountPass from '@/views/auth/account-pass.vue'
import { Dialog } from 'vant'
import EventBus from '@/common/event-bus'
export default {
  props: {},
  data() {
    return {
      psw: '',
      showDialog: false,
      showReport: false,
      interviewId: '',
      error: '',
      loginVisible: false,
      isFrom:''
    }
  },
  created() {
    if (dd.env.platform !== 'notInDingTalk') {
      console.log('在钉钉环境内')
      //如果control为true，则onSuccess将在发生按钮点击事件被回调
      dd.biz.navigation.setRight({
        show: true, //控制按钮显示， true 显示， false 隐藏， 默认true
        control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
        text: '...', //控制显示文本，空字符串表示显示默认文本
        onSuccess: () => {
          dd.biz.util.share({
            type: 0, //分享类型，0:全部组件 默认； 1:只能分享到钉钉；2:不能分享，只有刷新按钮
            url: window.location.href,
            content: this.$t('view.report.login.text004'),
            title: this.$t('view.report.login.text005'),
            image: 'http://www.aidexianzhaopinguan.com/logo.png',
            onSuccess: result => {
              console.log('share    success!')
              console.log(result)
              //onSuccess将在分享完成之后回调
              /**/
            },
            onFail: e => {
              console.log(e)
            },
          })
        },
      })
    }
    let isFrom = this.$route.query?.isFrom
    if(isFrom && isFrom === 'miniapp'){
      this.isFrom = 'miniapp'
      document.title='AI猫渴模拟面试'
    }
    EventBus.$on('toLogin', this.toLogin)

    this.interviewId = this.$route.query.userid
    const psw = this.$route.query.pin
    if (psw) {
      sessionStorage.setItem('psw', psw)
    }

    if (sessionStorage.psw) {
      this.psw = sessionStorage.psw
      this.checkReportPwd()
    } else {
      this.showDialog = true
    }

    if(!this.token) {
      let cmd = this.$route.query?.companyId
      if(cmd) {
        this.aiMarkConfig(cmd)
      }
    }
  },
  beforeDestroy() {
    EventBus.$off('toLogin', this.toLogin)
  },
  components: {
    ReportContent,
    AccountPass,
    PersonalReport,
    PersonalReportL5,
  },
  methods: {
    //获取用户AI标注设置配置
    aiMarkConfig(comid) {
      getAiInterLabelConfig({ companyId: comid }).then(res => {
        if (res && res.code === 0) {
          let aicfg = {
            allStatus: res.data.allStatus === 1 ? true : false, //AI总分展示状态
            singleStatus: res.data.singleStatus === 1 ? true : false, //AI单题分数展示状态
            manualStatus: res.data.manualStatus === 1 ? true : false, //人工标注状态
            aiScoreStatus: res.data.aiScoreStatus === 1 ? true : false, //人工标注后，ai单题展示状态
          }
          this.$store.commit('SET_AICFG', aicfg)
        }
      })
    },
    confirmLogin(token) {
      console.log(token)
      const path = decodeURIComponent('/newShareReport')
      this.$router.push({
        path: '/auth',
        query: {
          ...this.$route.query,
          token: token,
          path,
          pin: this.psw,
        },
      })
    },
    toLogin(type) {
      console.log('toLogin')
      if (type === '1004') {
        this.$store.commit('setUserInfo', null)
        this.$store.commit('setToken', '')
        localStorage.removeItem('token')
      }
      if (this.token) {
        this.loginOut()
      } else {
        this.loginVisible = true
      }
    },

    loginOut() {
      Dialog.confirm({
        title: this.$t('view.report.login.text006'),
        confirmButtonColor: '#1989fa',
        closeOnClickOverlay: true,
      }).then(() => {
        this.$store.commit('setUserInfo', null)
        this.$store.commit('setToken', '')
        localStorage.removeItem('token')
        this.$notify({ type: 'success', message: this.$t('view.report.login.text007') })
        location.reload()
      })
    },
    //校验密码
    checkReportPwd(f) {
      let data = {
        id: this.interviewId,
        pin: this.psw,
        jobseekerChannelId: this.$route.query.jobseekerChannelId,
      }
      checkReportPwd(data).then(res => {
        if (res && res.code === 0) {
          if (sessionStorage) {
            sessionStorage.setItem('psw', this.psw)
          }
          this.showReport = true
          this.showDialog = false
          f && f(true)
        } else {
          this.showDialog = true
          f && f(false)
          this.$notify({ type: 'danger', message: this.$t('view.report.login.text008') })
        }
      })
    },
    closeDialog(a, done) {
      if (this.error) {
        return done(false)
      }
      this.checkReportPwd(done)
      // done(true)
    },
    validate() {
      if (!this.psw) {
        this.error = this.$t('view.report.login.text009')
        this.$toast({ message: this.$t('view.report.login.text009'), className: 'wordBreak' })
        this.showDialog = true
      } else {
        let rule = /^(\d|[a-zA-Z]){6,}$/
        let bool = rule.test(this.psw)
        if (!bool) {
          this.error = this.$t('view.report.login.text010')
          this.$toast({ message: this.$t('view.report.login.text010'), className: 'wordBreak' })
          this.showDialog = true
        } else {
          this.error = ''
        }
      }
    },
  },
  computed: {
    getAccountInfo() {
      const name = this.$store.state.userInfo.userName
      return this.$t('view.report.login.text011', { name })
    },
    token() {
      return this.$store.state.token || this.$store.state.userInfo?.token
    },
    // 是否是个人报告
    isPersonalReport() {
      const { mode } = this.$route.query
      return mode === 'personal'
    },
    isL5Position() {
      const { modetype } = this.$route.query
      return modetype === 'l5'
    },
    showLogin() {
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 20)
      return config?.inUse === 0 ? false : true
    },
  },
}
</script>
<style lang="scss" scoped>
.report-mobile_share {
  position: relative;
  // padding-bottom: 32px;
  .report-mobile_lgbtn {
    z-index: 99;
    position: fixed;
    bottom: 0;
    width: 100%;
    .lgbtn {
      width: 100%;
    }
  }
}
</style>
<style>
.van-toast--text.wordBreak .van-toast__text {
  word-break: break-word;
}
</style>